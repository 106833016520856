import React from "react";
import { useService } from "@app/hooks";
import { IUserService } from "@app/service/users";
import { TYPES } from "@app/service/types";
import { AsyncLoadList } from "./async-load-list";
import { ChatUserContact, IChatService } from "@app/service/chat";
import { MyChatContext } from "../../store";
import { useAsyncFn } from "@app/hooks/useAsyncFn";
import { GLGlobal } from "gl-commonui";
import { ChatActionType } from "../../reducer";
import * as Lodash from "lodash";
import "../../style/index.less";

interface IChatUserListProps {
    defaultUser: ChatUserContact;
    searchedUser: ChatUserContact;
}

const keyMapping = (user: ChatUserContact) => user.id;
const titleMapping = (user: ChatUserContact) => user.name;

const getSchoolIds = schools => schools.map(s => s.id);

const userProfile = GLGlobal.loginInfo().profile;

export const ChatUserList: React.FunctionComponent<IChatUserListProps> = props => {
    const [state, dispatch] = React.useContext(MyChatContext);
    const userservice = useService<IUserService>(TYPES.IUserService);
    const chatservice = useService<IChatService>(TYPES.IChatService);
    const [selectedUser, setSelectedUser] = React.useState<ChatUserContact>(
        null
    );
    const [extraUsers, setExtraUser] = React.useState<ChatUserContact[]>();

    const fetchChatGroup = async (attenders: Array<string>) =>
        await chatservice.createNewChat({ attenders });

    const [chatGroup, asyncFetchChatGroup] = useAsyncFn<string>(
        fetchChatGroup,
        [selectedUser]
    );

    const fetchChattedUsers = React.useCallback(
        async (schoolIds: Array<string>) =>
            await chatservice.getChattedUsers({ schoolIds }),
        []
    );

    const fetchUserAvatar = React.useCallback(
        async (id: string) =>
            await userservice.getUserAvatarSasUri({
                id,
                expirationminutes: 120
            }),
        []
    );

    const schoolIds = React.useMemo(
        () =>
            state.selectedSchool &&
            state.selectedSchool.length !== 0 &&
            getSchoolIds(state.selectedSchool),
        [state.selectedSchool]
    );

    const handleOnSelectUser = (user: ChatUserContact) => {
        user && setSelectedUser(user);
    };

    React.useEffect(() => {
        selectedUser && asyncFetchChatGroup([selectedUser.id, userProfile.sub]);
    }, [asyncFetchChatGroup]);

    React.useEffect(() => {
        if (!chatGroup.data) {
            return;
        }

        if (typeof chatGroup.data === "number") {
            dispatch({
                type: ChatActionType.UPDATE_SELECTED_GROUP,
                payload: {
                    groupId: chatGroup.data,
                    sender: { id: userProfile.sub, name: userProfile.name },
                    receiver: selectedUser
                }
            });
        } else {
            dispatch({
                type: ChatActionType.UPDATE_SELECTED_GROUP,
                payload: null
            });
        }
    }, [chatGroup]);

    React.useEffect(() => {
        if (!state.unReadMessages) {
            return;
        }

        setExtraUser(
            state.unReadMessages
                .filter(x =>
                    Lodash.intersection(x.relatedSchools, schoolIds).some(
                        id => !!id
                    )
                )
                .map(x => ({
                    id: x.senderId,
                    name: x.senderName
                }))
        );
    }, [state.unReadMessages]);

    const unReadCount = React.useMemo(() => {
        if (!state.unReadMessages) {
            return 0;
        }

        return state.unReadMessages.reduce((userMap, m) => {
            userMap[m.senderId] = m.unReadMessages.reduce(
                (sum, c) => sum + c.count,
                0
            );
            return userMap;
        }, {});
    }, [state.unReadMessages]);

    return (
        <div className="search-user-list">
            <AsyncLoadList
                fetchFn={fetchChattedUsers}
                deps={[schoolIds]}
                keyMapping={keyMapping}
                titleMapping={titleMapping}
                handleOnSelect={handleOnSelectUser}
                fetchAvatar={fetchUserAvatar}
                includeItem={props.searchedUser}
                badgeCount={unReadCount}
                defaultSelectItem={props.defaultUser}
                extraItems={extraUsers}
            />
        </div>
    );
};
