import * as React from "react";
import { ChatUnreadMessageModel, MESSAGE_REFRESH_TIME } from "@app/service/chat";
import { useChatMessageCount } from "./useChatMessageCount";

export function useChatMessageCountInterval(): [
    boolean,
    ChatUnreadMessageModel[]
] {
    const [loading, messages, asyncFetchUnreadMessages] = useChatMessageCount();

    React.useEffect(() => {
        asyncFetchUnreadMessages([]);

        const timer = setInterval(() => asyncFetchUnreadMessages([]), MESSAGE_REFRESH_TIME);

        return () => clearInterval(timer);
    }, [asyncFetchUnreadMessages]);

    return [loading, messages];
}
