import * as React from "react";
import { ChatThread, ChatUserContact, IChatService } from "@app/service/chat";
import { ChatWidget } from "@app/components/coach/chat/components/chat-widget";
import { fmtMsg } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";
import { GLGlobal } from "gl-commonui";
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { useAsyncFn } from "@app/hooks/useAsyncFn";
import "../../style/index.less";

export interface IChatMessagePanelProps {
    chatGroupId: string;
    chatThread: ChatThread;
    currentUser: ChatUserContact;
}

export const ChatMessagePanel: React.FunctionComponent<IChatMessagePanelProps> = props => {
    const chatThreadId = React.useMemo(
        () => props.chatThread && props.chatThread.id,
        [props.chatThread]
    );

    const chatMessageId = React.useMemo(
        () => props.chatThread && props.chatThread.messageId,
        [props.chatThread]
    );

    const messagePanel = React.useMemo(() => {
        return chatThreadId ? (
            <ChatWidget
                key={`${chatThreadId}-${chatMessageId}`}
                chatGroupId={props.chatGroupId}
                chatThreadId={chatThreadId}
                chatMessageId={chatMessageId}
                senderPlaceHolder={fmtMsg({
                    id: GSAdminLocale.SenderPlaceHolder
                })}
                autofocus={false}
                senderId={GLGlobal.loginInfo().profile.sub}
                receiver={props.currentUser}
                searchable={false}
                getBadgeCount={() => {}}
            />
        ) : (
            <>
                <div className="thread-message-content" />
                <div className="thread-message-send" />
            </>
        );
    }, [props.chatGroupId, chatThreadId, chatMessageId]);

    return <div className="thread-content-layout">{messagePanel}</div>;
};
