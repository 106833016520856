import * as React from "react";
import { Icon, Input } from "antd-min";
import "../../style/index.less";
import { useKeyPress } from "@app/hooks/useKeyPress";
import { KeyText } from "@app/util";

export interface IEditableTextProps<T extends {}> {
    title: string;
    initValue: string;
    handleOnSave: (title: string) => Promise<boolean>;
}

export function EditableText<T>(props: IEditableTextProps<T>) {
    const [text, setText] = React.useState<string>(props.initValue);
    const [editing, setEditing] = React.useState<boolean>(false);
    const enterPress = useKeyPress(KeyText.Enter);

    React.useEffect(() => {
        setText(props.initValue);
        setEditing(false);
    }, [props.initValue]);

    const handleOnEdit = () => setEditing(true);
    const handleOnSave = async () => {
        if (text === props.initValue) {
            setEditing(false);
            return;
        }

        if (props.handleOnSave) {
            const result = await props.handleOnSave(text);
            result && setEditing(false);
        } else {
            return;
        }
    };
    const handleOnCancel = () => {
        setText(props.initValue);
        setEditing(false);
    };
    const handleOnTextChange = (e: any) => setText(e.target.value);

    React.useEffect(() => {
       enterPress && editing && text && handleOnSave();
    }, [enterPress]);

    const editText = React.useMemo(() => {
        return (
            <div className="editable-text-group">
                {editing ? (
                    <Input defaultValue={text} onChange={handleOnTextChange} />
                ) : (
                    text && (
                        <pre
                            className="editable-text-title"
                            dangerouslySetInnerHTML={{
                                __html: text.replace(/\s/g, "&nbsp")
                            }}
                        />
                    )
                )}
            </div>
        );
    }, [editing, handleOnTextChange]);

    const editButton = React.useMemo(() => {
        return (
            <div className="editable-button-group">
                {editing ? (
                    <>
                        <Icon
                            type="check"
                            className="button-check"
                            onClick={handleOnSave}
                        />
                        <Icon
                            type="close"
                            className="button-close"
                            onClick={handleOnCancel}
                        />
                    </>
                ) : (
                    <Icon type="edit" onClick={handleOnEdit} />
                )}
            </div>
        );
    }, [editing, handleOnSave, handleOnEdit]);

    return (
        <div className="editable-text">
            {props.title && props.initValue && <span>{props.title}:</span>}
            {props.initValue && editText}
            {props.initValue && editButton}
        </div>
    );
}
