import * as React from "react";
import { MyChatStore } from "./store";
import { fmtMsg } from "../../util/index";
import { MainTitle } from "@app/components";
import { GSAdminLocale } from "../../locales/localeid";
import { ChatContentView } from "./component/chat-content";
import { withRouter, GLRouteComponentProps } from "gl-commonui";
import { UnReadThreadLinkView } from "./component/unread-thread-link";
import { ChatSchoolFilterView } from "./component/chat-school-filter";
import { ChatSelectView, IChatSelectViewRef } from "./component/chat-select";
import "./style/index.less";

interface IMyChatProps extends GLRouteComponentProps {}

const MyChat: React.FunctionComponent<IMyChatProps> = props => {
    const chatSelectRef = React.useRef<IChatSelectViewRef>(null);

    const [selectedUserThread, setSelectedUserThread] = React.useState<
        [string, string]
    >([null, null]);

    const handleOnOpenNextThread = (userId: string, threadId: string) =>
        setSelectedUserThread([userId, threadId]);

    const updateThread = React.useCallback(
        async (title: string) =>
            chatSelectRef.current &&
            (await chatSelectRef.current.updateThread(title)),
        []
    );

    return (
        <MyChatStore>
            <div className="page-container content-layout">
                <MainTitle plain={fmtMsg({ id: GSAdminLocale.MyChat })} />
                <div className="page-content">
                    <div className="page-content-layout">
                        <div className="page-header-layout">
                            <UnReadThreadLinkView
                                handleOnOpenNextThread={handleOnOpenNextThread}
                            />
                            <ChatSchoolFilterView />
                        </div>
                        <div className="page-main-layout">
                            <ChatSelectView
                                ref={chatSelectRef}
                                openNextThread={selectedUserThread}
                            />
                            <ChatContentView
                                handleOnUpdateThread={updateThread}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MyChatStore>
    );
};

export const MyChatPage = withRouter(MyChat);
