import * as React from "react";
import { Select } from "antd-min";
import { useChatSchool } from "../hooks";
import { MyChatContext } from "../store";
import { ChatActionType } from "../reducer";
import { ChatSchoolDataContract } from "@app/service/chat";
import "../style/index.less";
import { fmtMsg } from "@app/util";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";

export interface IChatSchoolFilterProps {}

const { Option } = Select;

const convertSchoolDataSource = (schools: ChatSchoolDataContract[]) => [
    { id: "All", name: fmtMsg({ id: GSAdminLocale.ListStatesAll }) },
    ...schools
];

export const ChatSchoolFilterView: React.FunctionComponent<IChatSchoolFilterProps> = props => {
    const [loading, schools] = useChatSchool();
    const [_, dispatch] = React.useContext(MyChatContext);

    React.useEffect(() => {
        schools &&
            dispatch({
                type: ChatActionType.UPDATE_SELECTED_SCHOOL,
                payload: schools
            });
    }, [schools]);

    const handleOnSelectChange = (id: string) => {
        dispatch({
            type: ChatActionType.UPDATE_SELECTED_SCHOOL,
            payload: id === "All" ? schools : schools.filter(x => x.id === id)
        });
    };

    return (
        <div className="search-school-select">
            <span>{fmtMsg({ id: SchoolLocale.CampusSchoolSelect })}</span>
            <Select
                onChange={handleOnSelectChange}
                defaultValue={fmtMsg({ id: GSAdminLocale.ListStatesAll })}
                loading={loading}
            >
                {schools &&
                    convertSchoolDataSource(schools).map(school => (
                        <Option
                            key={school.id}
                            value={school.id}
                            className="search-school-select-option"
                        >
                            <div className="search-school-select-item">
                                <div className="search-school-select-item-name">
                                    <span title={school.name}>{school.name}</span>
                                </div>
                            </div>
                        </Option>
                    ))}
            </Select>
        </div>
    );
};
