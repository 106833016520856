import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { ChatUnreadMessageModel, IChatService } from "@app/service/chat";
import { useAsyncFn } from "@app/hooks/useAsyncFn";
import { FnReturnPromise } from "@app/util";

export function useChatMessageCount(): [
    boolean,
    ChatUnreadMessageModel[],
    FnReturnPromise<ChatUnreadMessageModel>
] {
    const chatservice = useService<IChatService>(TYPES.IChatService);
    const fetchUnreadMessages = async senderIds =>
        await chatservice.getAllUnReadMessagesCount({ senderIds });

    const [unreadMessages, asyncFetchUnreadMessages] = useAsyncFn<
        ChatUnreadMessageModel
    >(fetchUnreadMessages, []);

    return [
        unreadMessages.loading,
        unreadMessages.data as any,
        asyncFetchUnreadMessages
    ];
}
