import * as React from "react";
import {
    DashboardLocale,
    GSAdminLocale,
    SchoolLocale
} from "@app/locales/localeid";
import { ChatThread, ChatUserContact } from "@app/service/chat";
import { fmtMsg } from "@app/util";
import { GLGlobal, RoleName } from "gl-commonui";
import { MyChatContext } from "../../store";
import { ChatThreadList, IChatThreadListRef } from "./chat-thread-list";
import { ChatThreadSelect } from "./chat-thread-select";
import { ChatUserList } from "./chat-user-list";
import { ChatUserSelect } from "./chat-user-select";

const userProfile = GLGlobal.loginInfo().profile;

export interface IChatSelectViewRef {
    updateThread: (title: string) => Promise<boolean>;
}
export interface IChatSelectViewProps {
    openNextThread: [string, string];
}

const ChatSelectViewComponent: React.FunctionComponent<IChatSelectViewProps> = (
    props,
    ref: React.Ref<IChatSelectViewRef>
) => {
    const [state] = React.useContext(MyChatContext);
    const threadListRef = React.useRef<IChatThreadListRef>(null);
    const [defaultUser, setDefaultUser] = React.useState<ChatUserContact>(null);
    const [defaultThread, setDefaultThread] = React.useState<ChatThread>(null);
    const [searchedUser, setSearchedUser] = React.useState<ChatUserContact>(
        null
    );
    const [searchedThread, setSearchedThread] = React.useState<ChatThread>(
        null
    );

    const handleOnSearchedUser = React.useCallback(
        async (user: ChatUserContact) => setSearchedUser(user),
        []
    );

    const handleOnSearchedThread = React.useCallback(
        async (thread: ChatThread | any) => setSearchedThread(thread),
        []
    );

    React.useImperativeHandle(
        ref,
        () => ({
            updateThread: async (title: string) =>
                threadListRef.current &&
                (await threadListRef.current.updateThread(title))
        }),
        []
    );

    const userSelectTitle = userProfile.roles.includes(RoleName.trainer)
        ? fmtMsg({ id: DashboardLocale.LandingTabTeacherText })
        : fmtMsg({ id: SchoolLocale.ClassesColumnTrainerName });
    const userSelectPlaceholder = userProfile.roles.includes(RoleName.trainer)
        ? fmtMsg({ id: GSAdminLocale.ChatTeacherName })
        : fmtMsg({ id: GSAdminLocale.ChatTrainerName });

    const createNewThread = React.useCallback(() => {
        state.selectedGroup &&
            threadListRef.current &&
            threadListRef.current.createNewThread();
    }, [state.selectedGroup]);

    React.useEffect(() => {
        const [userId, threadId] = props.openNextThread;
        setDefaultUser({ id: userId });
        setDefaultThread({ id: threadId });
    }, [props.openNextThread]);

    const selectedSchools = React.useMemo(
        () => state.selectedSchool && state.selectedSchool.map(x => x.id),
        [state.selectedSchool]
    );

    React.useEffect(() => {
        setSearchedUser(null);
        setSearchedThread(null);
        setDefaultUser(null);
    }, [state.selectedSchool, state.selectedGroup, state.selectedThread]);

    return (
        <div className="page-search-container">
            <div className="search-user-layout">
                <div className="search-user-title">
                    <label>{userSelectTitle}</label>
                </div>
                <ChatUserSelect
                    schoolIds={selectedSchools}
                    placeholder={userSelectPlaceholder}
                    handleOnSearchedUser={handleOnSearchedUser}
                />
                <ChatUserList
                    defaultUser={defaultUser}
                    searchedUser={searchedUser}
                />
            </div>
            <div className="search-thread-layout">
                <div className="search-thread-title">
                    <span>{fmtMsg({ id: GSAdminLocale.ChatThread })}</span>
                    <a
                        onClick={createNewThread}
                        className={
                            !state.selectedGroup || !state.selectedGroup.groupId
                                ? "disabled-link"
                                : "enabled-link"
                        }
                    >{`+ ${fmtMsg({
                        id: GSAdminLocale.ChatThreadNew
                    })}`}</a>
                </div>
                <ChatThreadSelect
                    chatGroupId={
                        state.selectedGroup && state.selectedGroup.groupId
                    }
                    placeholder={fmtMsg({
                        id: GSAdminLocale.MyChatThreadSearch
                    })}
                    handleOnSearchedThread={handleOnSearchedThread}
                    disabled={
                        !state.selectedGroup || !state.selectedGroup.groupId
                    }
                />
                <ChatThreadList
                    ref={threadListRef}
                    defaultThread={defaultThread}
                    searchedThread={searchedThread}
                />
            </div>
        </div>
    );
};

export const ChatSelectView = React.forwardRef(ChatSelectViewComponent);
