import * as React from "react";
import { Badge } from "antd-min";
import * as Utils from "../utils";
import { MyChatContext } from "../store";
import { ChatActionType } from "../reducer";
import { useChatMessageCountInterval } from "../hooks/useChatMessageCountInterval";
import "../style/index.less";
import { fmtMsg } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";

export interface IUnReadThreadLinkProps {
    handleOnOpenNextThread: (userId: string, threadId: string) => void;
}

export const UnReadThreadLinkView: React.FunctionComponent<IUnReadThreadLinkProps> = props => {
    const [_, dataSource] = useChatMessageCountInterval();
    const [state, dispatch] = React.useContext(MyChatContext);

    React.useEffect(() => {
        dataSource &&
            dispatch({
                type: ChatActionType.UPDATE_UNREADMESSAGES,
                payload: dataSource
            });
    }, [dataSource]);

    const readNextUnreadThread = () => {
        if (!state.unReadMessages || state.unReadMessages.length === 0) {
            return;
        }

        const messages = state.unReadMessages[0];

        if (!messages || !messages.unReadMessages) {
            return;
        }

        props.handleOnOpenNextThread(
            messages.senderId,
            messages.unReadMessages[0] && messages.unReadMessages[0].threadId
        );
    };

    const count = React.useMemo(
        () => state.unReadMessages && Utils.parseCount(state.unReadMessages),
        [state.unReadMessages]
    );

    return (
        <div className="unread-thread-link">
            {!!count && <Badge count={count} />}
            <a onClick={readNextUnreadThread}>{fmtMsg({ id: GSAdminLocale.OpenNextUnReadThread })}</a>
        </div>
    );
};
