import { useService } from "@app/hooks";
import * as React from "react";
import { useAsyncFn } from "@app/hooks/useAsyncFn";
import { ChatSchoolDataContract } from "@app/service/chat";
import { IResourceService } from "@app/service/resources";
import { TYPES } from "@app/service/types";

export function useChatSchool(): [boolean, ChatSchoolDataContract[]] {
    const resourceservice = useService<IResourceService>(
        TYPES.IResourceService
    );

    const fetchUserSchools = async () => await resourceservice.getChatSchools();

    const [relatedSchools, asyncFetchUserSchools] = useAsyncFn<
        ChatSchoolDataContract
    >(fetchUserSchools, []);

    React.useEffect(() => {
        asyncFetchUserSchools();
    }, [asyncFetchUserSchools]);

    return [relatedSchools.loading, relatedSchools.data as []];
}
