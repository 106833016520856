import * as React from "react";
import * as Utils from "../../utils";
import { useService } from "@app/hooks";
import { AsyncLoadList } from "./async-load-list";
import { ChatThread, IChatService } from "@app/service/chat";
import { TYPES } from "@app/service/types";
import { Modal, Input } from "antd-min";
import { MyChatContext } from "../../store";
import { useChatGroupId } from "../../hooks/useChatGroupId";
import { ChatActionType } from "../../reducer";
import { useChatMessageCount } from "../../hooks";
import * as Lodash from "lodash";
import "../../style/index.less";
import { useKeyPress } from "@app/hooks/useKeyPress";
import { fmtMsg, KeyText } from "@app/util";
import { GSAdminLocale, SurveyLocale } from "@app/locales/localeid";

export interface IChatThreadListProps {
    defaultThread: any;
    searchedThread: any;
}

export interface IChatThreadListRef {
    createNewThread: () => void;
    updateThread: (title: string) => Promise<boolean>;
}

const keyMapping = (thread: ChatThread | any) => parseInt(thread.id);
const titleMapping = (thread: ChatThread | any) =>
    thread.title && thread.title.replace(/\s/g, "&nbsp");

const ChatThreadListComponent: React.FunctionComponent<IChatThreadListProps> = (
    props,
    ref: React.Ref<IChatThreadListRef>
) => {
    const [state, dispatch] = React.useContext(MyChatContext);
    const [reload, setReload] = React.useState<boolean>(false);

    const [visiable, setVisiable] = React.useState<boolean>(false);
    const [defaultThread, setDefaultThread] = React.useState<{}>(null);
    const [currentThread, setCurrentThread] = React.useState<ChatThread>(null);
    const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
    const [threadTitle, setThreadTitle] = React.useState<string>("");
    const [extraItems, setExtraItems] = React.useState<ChatThread[]>();

    const enterPress = useKeyPress(KeyText.Enter);

    const [_, unreadMessages, asyncFetchUnreadMessages] = useChatMessageCount();

    React.useEffect(() => {
        currentThread && currentThread.id && asyncFetchUnreadMessages();
    }, [currentThread]);

    React.useEffect(() => {
        setDefaultThread(props.defaultThread);
    }, [props.defaultThread]);

    React.useEffect(() => {
        unreadMessages &&
            dispatch({
                type: ChatActionType.UPDATE_UNREADMESSAGES,
                payload: unreadMessages
            });
    }, [unreadMessages]);

    const chatservice = useService<IChatService>(TYPES.IChatService);

    const [groupId] = useChatGroupId();

    const fetchChatThreads = async (groupId: string) =>
        await chatservice.getChatThreads({ groupId });

    const readAllThreadMessages = async (senderId: string, threadId: string) =>
        await chatservice.makeAllMessagesRead({ senderId, threadId });

    const updateThreadTitle = React.useCallback(
        async (title: string) => {
            const newTitle = title && title.trim();
            if (!newTitle || newTitle.length === 0){
                return;
            }
            const threadId = currentThread && currentThread.id;
            const result = await chatservice.updateThread({
                groupId,
                threadId,
                title: newTitle
            });

            if (!result) {
                setReload(r => !r);
                setDefaultThread({ id: threadId });
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        },
        [groupId, currentThread]
    );

    const addNewThread = () => {
        setVisiable(true);
    };
    const handleOnCancel = () => {
        setVisiable(false);
    };

    const handleOnCreateThread = React.useCallback(
        Lodash.throttle(
            async () => {
                if (!threadTitle || threadTitle.trim().length === 0){
                    return;
                }
                const newId = await chatservice.addNewThread({
                    groupId,
                    title: threadTitle
                });

                if (newId && typeof newId === "number") {
                    setDefaultThread({ id: newId });
                    setConfirmLoading(true);
                    setVisiable(false);
                    setConfirmLoading(false);
                    setThreadTitle("");
                    setReload(r => !r);
                }
            },
            1000,
            { trailing: false }
        ),
        [groupId, threadTitle, visiable, confirmLoading]
    );

    React.useImperativeHandle(
        ref,
        () => ({
            createNewThread: addNewThread,
            updateThread: updateThreadTitle
        }),
        [currentThread, updateThreadTitle, addNewThread]
    );

    const handleOnSelectThread = async (thread: ChatThread) => {
        state.selectedGroup &&
            state.selectedGroup.receiver &&
            thread &&
            (await readAllThreadMessages(
                state.selectedGroup.receiver.id,
                thread.id
            ));
        thread && setCurrentThread(thread);
    };

    const handleOnModalTextChange = (e: any) => setThreadTitle(e.target.value);

    React.useEffect(() => {
        enterPress && groupId && threadTitle && handleOnCreateThread();
    }, [enterPress]);

    React.useEffect(() => {
        currentThread &&
            dispatch({
                type: ChatActionType.UPDATE_SELECTED_THREAD,
                payload: currentThread
            });

        currentThread && setDefaultThread(null);
    }, [currentThread]);

    React.useEffect(() => {
        state.selectedSchool &&
            state.selectedGroup &&
            dispatch({
                type: ChatActionType.UPDATE_SELECTED_THREAD,
                payload: null
            });
    }, [state.selectedSchool, state.selectedGroup]);

    React.useEffect(() => {
        if (
            !state.unReadMessages ||
            !state.selectedGroup ||
            !state.selectedGroup.receiver
        ) {
            return;
        }

        const data = state.unReadMessages.find(
            x => x.senderId === (state.selectedGroup.receiver.id as any)
        );

        data &&
            setExtraItems(
                data.unReadMessages.map(x => ({
                    id: x.threadId,
                    title: x.threadTitle
                }))
            );
    }, [state.unReadMessages]);

    const unReadCount = React.useMemo(() => {
        if (
            !state.unReadMessages ||
            !state.selectedGroup ||
            !state.selectedGroup.receiver
        ) {
            return {};
        }

        const data = state.unReadMessages.find(
            x => x.senderId === (state.selectedGroup.receiver.id as any)
        );

        return (
            data &&
            data.unReadMessages.reduce((map, m) => {
                map[m.threadId] = m.count;
                return map;
            }, {})
        );
    }, [state.unReadMessages, state.selectedGroup]);

    return (
        <div className="search-thread-list">
            <AsyncLoadList
                fetchFn={fetchChatThreads}
                deps={[groupId]}
                keyMapping={keyMapping as any}
                titleMapping={titleMapping}
                handleOnSelect={handleOnSelectThread}
                reload={reload}
                includeItem={props.searchedThread}
                updateItem={Utils.updateThread}
                badgeCount={unReadCount}
                autoClear={!state.selectedGroup}
                defaultSelectItem={defaultThread}
                extraItems={extraItems}
            />
            <Modal
                title={`${fmtMsg({ id: SurveyLocale.CreateNewPoll })} ${fmtMsg({
                    id: GSAdminLocale.ChatThread
                })}`}
                visible={visiable}
                onOk={handleOnCreateThread}
                confirmLoading={confirmLoading}
                onCancel={handleOnCancel}
                destroyOnClose
            >
                <div className="thread-create-modal">
                    <div className="thread-create-modal-content">
                        <span>{fmtMsg({ id: GSAdminLocale.ChatThread })}:</span>
                        <Input onChange={handleOnModalTextChange} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export const ChatThreadList = React.forwardRef(ChatThreadListComponent);
