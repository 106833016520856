import * as React from "react";
import { EditableText } from "./editable-text";
import { ChatMessagePanel } from "./chat-message-panel";
import { MyChatContext } from "../../store";
import { fmtMsg } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";

export interface IChatContentProps {
    handleOnUpdateThread: (title: string) => Promise<boolean>;
}

export const ChatContentView: React.FunctionComponent<IChatContentProps> = props => {
    const [state] = React.useContext(MyChatContext);

    return (
        <div className="page-thread-container">
            <div className="thread-title-layout">
                {state.selectedThread && (
                    <EditableText
                        title={fmtMsg({ id: GSAdminLocale.ChatThread })}
                        initValue={
                            state.selectedThread && state.selectedThread.title
                        }
                        handleOnSave={props.handleOnUpdateThread}
                    />
                )}
            </div>
            <ChatMessagePanel
                chatGroupId={state.selectedGroup && state.selectedGroup.groupId}
                currentUser={state.selectedGroup && state.selectedGroup.receiver}
                chatThread={state.selectedThread}
            />
        </div>
    );
};
