import * as React from "react";
import { MyChatContext } from "../store";

export function useChatGroupId(): [string] {
    const [state] = React.useContext(MyChatContext);

    const groupId = React.useMemo(
        () => state.selectedGroup && state.selectedGroup.groupId,
        [state.selectedGroup]
    );

    return [groupId];
}
