import * as React from "react";
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { ChatUserContact } from "@app/service/chat";
import { IResourceService } from "@app/service/resources";
import { DebounceSelect } from "./debounce-select";
import "../../style/index.less";

export interface IChatUserSelectProps {
    placeholder: string;
    schoolIds: string[];
    handleOnSearchedUser: (selectedUser: ChatUserContact) => void;
}

const keyMapping = (user: ChatUserContact) => user.id;
const labelMapping = (user: ChatUserContact) => user.name;

export const ChatUserSelect: React.FunctionComponent<IChatUserSelectProps> = props => {
    const resourceservice = useService<IResourceService>(
        TYPES.IResourceService
    );
    const searchChatUsers = async (searchKey: string) =>
        await resourceservice.getChatUsers({
            schoolIds: props.schoolIds,
            searchKey
        });

    return (
        <>
            <div className="search-user-lookfor">
                <DebounceSelect<ChatUserContact>
                    keyMapping={keyMapping}
                    labelMapping={labelMapping}
                    placeholder={props.placeholder}
                    fetchFn={searchChatUsers}
                    fetchFnDeps={[resourceservice]}
                    handleOnSelectItemChange={props.handleOnSearchedUser}
                    autoClear={props.schoolIds}
                />
            </div>
        </>
    );
};
